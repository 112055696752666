// ----------------------------------------------------------------------
// ----------------------------------------------------------------------
// ----------------------------------------------------------------------
// ----------------------------------------------------------------------
import { DT_ROUTES } from '@rflex-spa/react-core/dist/routes/paths';

function path(root, sublink) {
  if (root && root !== '/') {
    return `${root}${sublink}`;
  }
  return sublink;
}

const AUTH_ROOT = '/auth';
const DASHBOARD_ROOT = '/';
const APP_REPORTS_ROOT = '/report';
const APP_WORKERS_ROOT = '/workers';
const APP_DT_REPORTS_ROOT = '/dt/report';
const APP_PROTOCOL_IMPROVEMENT = '/protocol-improvement';
const APP_MAINTAINERS = '/maintainer';
const APP_LEAVE = '/leave';
const APP_DOCUMENT_MANAGEMENT = '/gestion-documentos';
const APP_IMPORTERS = '/importers';

// ----------------------------------------------------------------------

export const AUTH_ROUTES = {
  root: AUTH_ROOT,
  login: path(AUTH_ROOT, '/login'),
  register: path(AUTH_ROOT, '/register'),
  loginUnprotected: path(AUTH_ROOT, '/login-unprotected'),
  registerUnprotected: path(AUTH_ROOT, '/register-unprotected'),
  verify: path(AUTH_ROOT, '/verify'),
  resetPassword: path(AUTH_ROOT, '/reset-password'),
  newPassword: path(AUTH_ROOT, '/new-password'),
};

export const ROUTES = {
  root: DASHBOARD_ROOT,
  enterValidationCode: path(DASHBOARD_ROOT, '/ingresar-codigo-validacion/:token'),
  home: path(DASHBOARD_ROOT, '/home'),
  teletrabajo: path(DASHBOARD_ROOT, '/teletrabajo'),
  closingPeriods: path(DASHBOARD_ROOT, '/closing-periods'),
  unitsMaintainer: path(DASHBOARD_ROOT, '/units-maintainer'),
  permissionDenied: path(DASHBOARD_ROOT, '/permission-denied'),
  hashValidation: path(DASHBOARD_ROOT, '/hash-validation'),
  user: {
    root: path(DASHBOARD_ROOT, '/user'),
    new: path(DASHBOARD_ROOT, '/user/new'),
    list: path(DASHBOARD_ROOT, '/user/list'),
    profile: path(DASHBOARD_ROOT, '/user/profile'),
    account: path(DASHBOARD_ROOT, '/user/account'),
    edit: (name) => path(DASHBOARD_ROOT, `/user/${name}/edit`),
  },
  clockDevices: path(DASHBOARD_ROOT, '/clock-devices'),
  spreadsheets: path(DASHBOARD_ROOT, '/spreadsheets'),
  spreadsheetDetail: path(DASHBOARD_ROOT, 'spreadsheets/:unitAbbreviation/:sheetName/:mode'),
  timeClock: path(DASHBOARD_ROOT, '/time-clock'),
  extraHours: path(DASHBOARD_ROOT, '/extra-hours')
};

export const APP_WORKERS_ROUTES = {
  root: APP_WORKERS_ROOT,
  workers: path(APP_WORKERS_ROOT, '/staff'),
  singleSheet: path(APP_WORKERS_ROOT, '/single-sheet'),
};

export const APP_REPORT_ROUTES = {
  root: APP_REPORTS_ROOT,
  called: path(APP_REPORTS_ROOT, '/called'),
  markCorrection: path(APP_REPORTS_ROOT, '/mark-correction'),
  payrollMaintainer: path(APP_REPORTS_ROOT, '/payroll'),
  balanceHours: path(APP_REPORTS_ROOT, '/balance-hours'),
  rawShift: path(APP_REPORTS_ROOT, '/raw-shift'),
  responsibilityBonus: path(APP_REPORTS_ROOT, '/responsability-bonus'),
  improvements: path(APP_REPORTS_ROOT, '/improvements'),
  honorarium: path(APP_REPORTS_ROOT, '/honorarium'),
  hoursNotWorked: path(APP_REPORTS_ROOT, '/hours-not-worked'),
  holidaysTime: path(APP_REPORTS_ROOT, '/holidays-time'),
  puntualityBonus: path(APP_REPORTS_ROOT, '/puntuality-bonus'),
  timeClock: path(APP_REPORTS_ROOT, '/time-clock'),
  shiftChanges: path(APP_REPORTS_ROOT, '/shift-changes'),
  extraShifts: path(APP_REPORTS_ROOT, '/extra-shifts'),
  attendanceSheets: path(APP_REPORTS_ROOT, '/attendance-sheets'),
  personnelBySector: path(APP_REPORTS_ROOT, '/personnel-by-sector'),
  paymentInterface: path(APP_REPORTS_ROOT, '/payment-interface'),
  bukPaymentInterface: path(APP_REPORTS_ROOT, '/buk-payment-interface'),
  leave: path(APP_REPORTS_ROOT, '/leave'),
  causesOfDismissals: path(APP_REPORTS_ROOT, '/causes-of-dismissals'),
  punches: path(APP_REPORTS_ROOT, '/punches'),
  punchesWithoutShift: path(APP_REPORTS_ROOT, '/punches-without-shift'),
  // TODO: Refactorizar y cambiar por rutas correspondientes, no utilizar rutas genericas con parametros.
  genericReportGDP: (route) => path(APP_REPORTS_ROOT, `/${route}`),
};

export const APP_DT_REPORTS_ROUTES = {
  root: APP_DT_REPORTS_ROOT,
  assistance: path(APP_DT_REPORTS_ROOT, '/assistance'),
  dailyWork: path(APP_DT_REPORTS_ROOT, '/daily-work'),
  sundayHolidays: path(APP_DT_REPORTS_ROOT, '/sunday-holidays'),
  modificationsAlterationsShift: path(APP_DT_REPORTS_ROOT, '/modifications-alterations-shift'),
};

export const APP_DT_ROUTES = {
  root: DT_ROUTES.root,
  hashValidation: path(DT_ROUTES.root, '/hash-validation'),
};

export const APP_PROTOCOL_IMPROVEMENT_ROUTES = {
  root: APP_PROTOCOL_IMPROVEMENT,
  contract: path(APP_PROTOCOL_IMPROVEMENT, '/contract'),
  sector: path(APP_PROTOCOL_IMPROVEMENT, '/sector'),
  shiftTypeAndRotations: path(APP_PROTOCOL_IMPROVEMENT, '/shift-type-and-rotations'),
  absenceShiftDetail: path(APP_PROTOCOL_IMPROVEMENT, '/absence-shift-detail'),
};

export const APP_LEAVE_ROUTES = {
  root: APP_LEAVE,
  foodlaw: path(APP_LEAVE, '/foodlaw'),
  medicalRestLeave: path(APP_LEAVE, '/medical-rest-leave'),
  bulkLeaves: path(APP_LEAVE, '/bulk-leaves'),
};

export const APP_MAINTAINERS_ROUTES = {
  root: APP_MAINTAINERS,
  configShift: path(APP_MAINTAINERS, '/config-shift'),
  audit: path(APP_MAINTAINERS, '/audit'),
  administrators: path(APP_MAINTAINERS, '/administrators'),
  roles: path(APP_MAINTAINERS, '/roles'),
};

export const APP_DOCUMENT_MANAGEMENT_ROUTES = {
  root: APP_DOCUMENT_MANAGEMENT,
  documentValidation: path(APP_DOCUMENT_MANAGEMENT, '/solicitud-validacion')
};

export const APP_IMPORTERS_ROUTES = {
  root: APP_IMPORTERS,
  shiftImporter: path(APP_IMPORTERS, '/shift-importer'),
};
